import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import SecondHeader from "../components/SecondHeader";
import Navbar from "../components/Navbar";
import SongCover from "../components/Music/SongCover";
import { IoIosArrowForward } from "react-icons/io";
import { SiApplemusic, SiSpotify } from "react-icons/si";

export default function Home(props) {
  const losBorjasTour = [
    {
      poster_url: "./events/10-05-24.jpeg",
      place: "Maturín | 10 Mayo",
      url: "https://wa.me/message/ZYZDQQO6DWKH",
      disabled: true,
      last: false,
    },
    {
      poster_url: "./events/17-05-lecheria.jpeg",
      place: "Lechería | 17 Mayo",
      url: "https://losborjasplc.tuentradaweb.com/?fbclid=PAZXh0bgNhZW0CMTEAAaZyvL36Yl6_SK4nGcFxpMDcDDk9xmnCNjoqG8dCDDiWTg2U3PaMnVEUUng_aem_AZOLMj3wHjH7H5aCFyNfYOwjInHa5qIK55hiBn7FcFd8uTjJZ7j-XpLpywgEwM7TsmwrVQixCfilfuJMJd-6PaQX",
      disabled: true,
      last: false,
    },
    {
      poster_url: "./events/27-03-24.jpeg",
      place: "Margarita | 27 Marzo",
      url: "https://maketicket.com.ve/evento/los-borjas-margarita?fbclid=PAAaYEe4nGoQExgjm65Sl50lmNBiuiFmr-ZLz-OH3hrbgaA6Zj4Lr8smULDxA_aem_Aa5_xaimEIaqmc7BH47z8S7mobGvkIEe9ca1XRAxvanpHgJe47T0LqdU0wNCcBEKOcU",
      disabled: true,
    },
    {
      poster_url: "./events/05-04-24.jpeg",
      place: "Barquisimeto | 05 Abril",
      url: "https://losborjas2024.ticketmundo.com/?fbclid=PAAabRC51N4h4Jie8IYBWRez0TNbKycjybElr5PyottWRGAmMHFLA8HOvuo4g_aem_Aa47l6yggQUocOASAGH9X-D0BaFwZy9KGa1PBp1AnvDunZo9SDSa7QbvPYP018MDuVg",
      disabled: true,
    },
    {
      poster_url: "./events/12-04-24.jpeg",
      place: "Maracaibo | 12 Abril",
      url: "https://mdticket.com/tienda/evento/LOS_BORJAS_Tour_2024/?fbclid=PAAaayq2gpojs8vXziHXmYVnOcCs0PRzBXctSdrCfYFVs2QDI89bI3Tq6n010_aem_Aa4cstJOigjYqbDE9c-Uj7tXx4iTwpEU6e1veXl1ehkcv_MHbdDGke6KU_jQJFKH-_U",
      disabled: true,
      last: true,
    },
  ];
  const events = [
    {
      poster_url: "./events/07-09-24.jpeg",
      place: "Chicago | 07 Septiembre",
      url: "https://www.tickeri.com/events/klgj8a12m0ya/ronald-borjas?fbclid=PAZXh0bgNhZW0CMTEAAaay_dRH1vb5s1a_4A_ESZvgblZfd6OKmrx23maLUjUcmtYvi8xDKe3dX0I_aem_9z7MkJKFEv8wJdBphKCrYg",
      disabled: false,
    },
    {
      poster_url: "./events/25-09-24.jpeg",
      place: "Madrid | 25 Septiembre",
      url: "https://www.passline.com/eventos/ronald-borjas-madrid-tour-espana-2024?fbclid=PAAaZ1QEUhNFXiQj5b43d4VpGejUU7Fxmjz1DpMOCNlU4gEcYCeRrhm0aqKtE_aem_Aa6Wk3M4Rsc3pw4dD-Lr6kqvnhw6rwOuqu8pwPQYhOECbzw-aqYKaRuz-mnOD2AJhWI",
      disabled: false,
    },
    {
      poster_url: "./events/26-09-24.jpeg",
      place: "Barcelona | 26 Septiembre",
      url: "https://www.passline.com/eventos/ronald-borjas-barcelona-tour-espana-2024?fbclid=PAAabbOqpNjG_jwG1StXR8M-TNqxIGrpkHAXJloDfDP7_iGpVGgYNcuCNROQc_aem_Aa6_ZPNyO0N1eGvf4jWPtoc_HDvUCwPle6mbdRb-PL9VGBC2uMR9feQ1rnbM0qfGkTI",
      disabled: false,
    },
    {
      poster_url: "./events/28-09-24.jpeg",
      place: "Tenerife | 28 Septiembre",
      url: "https://www.passline.com/eventos/ronald-borjas-tenerife?fbclid=PAZXh0bgNhZW0CMTEAAaZzD5XPO4FHX0fc-FLU91CjNpD6rTWsAF4RQDHa5sytx-6zwWpboSYPAlo_aem_G6T1_a2Q4o_j9W8pBDplJQ",
      disabled: false,
    },
    {
      poster_url: "./events/09-11-24.jpeg",
      place: "Utah | 09 Noviembre",
      url: "tickeri.com/events/qx0m8gadzypn/7mo-amanecer-de-feria-en-salt-lake-city-utah-",
      disabled: false,
    },
    {
      poster_url: "./events/26-05-dallas.png",
      place: "Dallas | 26 Mayo",
      url: "https://www.tickeri.com/search?q=Ronald+Borjas+&location=%7B%22lat%22%3A32.7766642%2C%22lng%22%3A-96.79698789999999%2C%22p%22%3A%22Dallas%2C+Texas%2C+EE.+UU.%22%7D&Search=",
      ended: true,
      disabled: true
    },
    {
      poster_url: "./events/25-05-houston.png",
      place: "Houston | 25 Mayo",
      url: "https://www.tickeri.com/search?q=Ronald+Borjas+&location=%7B%22lat%22%3A29.7604267%2C%22lng%22%3A-95.3698028%2C%22p%22%3A%22Houston%2C+Texas%2C+EE.+UU.%22%7D&Search=",
      ended: true,
      disabled: true
    },
    {
      poster_url: "./events/24-05-miami.png",
      place: "Miami | 24 Mayo",
      url: "https://www.tickeri.com/search?q=Ronald+Borjas+&location=%7B%22lat%22%3A25.7616798%2C%22lng%22%3A-80.1917902%2C%22p%22%3A%22Miami%2C+Florida%2C+EE.+UU.%22%7D&Search=",
      ended: true,
      disabled: true
    },
    {
      poster_url: "./events/23-05-orlando.png",
      place: "Orlando | 23 Mayo",
      url: "https://www.tickeri.com/search?q=Ronald+Borjas+&location=%7B%22lat%22%3A28.5383832%2C%22lng%22%3A-81.3789269%2C%22p%22%3A%22Orlando%2C+Florida%2C+EE.+UU.%22%7D&Search=",
      ended: true,
      disabled: true
    },
    {
      poster_url: "./events/09-02-24.jpeg",
      place: "Santiago de Chile | 09 Febrero",
      url: "https://www.puntoticket.com",
      disabled: true,
    },
    {
      poster_url: "./events/16-02.jpeg",
      place: "Buenos Aires | 16 Febrero",
      url: "https://www.passline.com/eventos/ronald-borjas-en-buenos-aires",
      disabled: true,
      last: false,
    },
    {
      poster_url: "./events/17-02-24.jpeg",
      place: "Monte Video | 17 Febrero",
      url: "https://www.passline.com",
      disabled: true,
      last: false,
    },
    {
      poster_url: "./events/21-02.jpeg",
      place: "Lima | 21 Febrero",
      url: "https://www.passline.com/eventos/ronald-borjas-en-concierto-peru",
      disabled: true,
      last: false,
    },
    {
      poster_url: "./events/23-02.jpeg",
      place: "Bogota | 23 Febrero",
      url: "https://www.passline.com/eventos/ronald-borjas-bogota",
      disabled: true,
      last: false,
    },
    {
      poster_url: "./events/24-02.jpeg",
      place: "Medellin | 24 Febrero",
      url: "https://www.passline.com/eventos/ronald-borjas-medellin",
      disabled: true,
      last: false,
      ended: true,
    },
    {
      poster_url: "./events/25-02.jpeg",
      place: "Barranquilla | 25 Febrero",
      url: "https://www.passline.com/eventos/ronald-borjas-en-barranquilla",
      disabled: true,
      last: false,
      ended: true,
    },
    {
      poster_url: "./events/01-03.jpeg",
      place: "Panama | 01 Marzo",
      url: "https://www.passline.com/eventos/ronald-borjas-en-panama",
      disabled: true,
      last: true,
    },
  ];
  useEffect(() => {
    document.title = props.title || "";
    setTimeout(() => {
      if (window.location.hash === "#conciertos") {
        const element = document.getElementById("conciertos");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else if (window.location.hash === "#los-borjas") {
        const element = document.getElementById("los-borjas");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 1000);
  }, [props.title, window.location]);
  return (
    <div>
      <Header />
      <div className="divider bg-[#000] h-[50px]"></div>
      <div
        id="music"
        className='bg-fixed mt-[-25px] bg-[url("./assets/joyas-bg.jpg")] bg-cover bg-end md:bg-center py-[150px] flex flex-col justify-center px-[5%]'
      >
        <div className="xl:container mx-auto">
          <h1 className="text-white font-bold text-5xl uppercase">
            Joyas que bailan
          </h1>
          <hr className="text-white font-bold text-5xl mb-8 pb-2 border-t-[3px] border-[#DAFF00] w-52 mt-2" />
          <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-12 justify-center">
            <SongCover
              imgUrl="./joyas-que-bailan.jpeg"
              title="Te mentiría"
              spotify="https://open.spotify.com/track/1rtxjLsNE7WKVkZjDwYTwe?si=90f5e6c53101446b"
              appleMusic="https://music.apple.com/co/album/te-mentir%C3%ADa/1741987371?i=1741987374"
              youtube="https://www.youtube.com/watch?v=rbX24o742Ww"
            />
            <SongCover
              imgUrl="./joyas-que-bailan.jpeg"
              title="Atado a Tu Amor"
              spotify="https://open.spotify.com/track/3ZmpNK5R50513ncyEv4ynd?si=973e9acc9b794273"
              appleMusic="https://music.apple.com/co/album/atado-a-tu-amor/1741987371?i=1741987375"
              youtube="https://www.youtube.com/watch?v=Pm37pueEmqU"
            />
            <SongCover
              imgUrl="./joyas-que-bailan.jpeg"
              title="Desnuda"
              spotify="https://open.spotify.com/track/3NnFUiVf0Spt31xexxJPwf?si=e579dc21a6a74da2"
              appleMusic="https://music.apple.com/co/album/desnuda/1741987371?i=1741987376"
              youtube="https://www.youtube.com/watch?v=SFE34Dr3fL8"
            />
            <div className="flex justify-center md:justify-center grid-cols-1 md:col-span-3">
              <Link to="/music" className="bg-white text-black px-4 flex items-center text-md w-[260px] justify-center py-4 font-semibold rounded-2xl px-8 cursor-pointer hover:bg-black hover:text-[#DAFF00] transition duration-300 hover:ring-[1px] ring-[#DAFF00]">
                Ver todo el album
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="divider-2 bg-[#000] h-[50px]"></div>
      <div
        id="music"
        className='mt-[-25px] bg-[url("./assets/music-bg.jpg")] bg-cover bg-end md:bg-center py-[150px] flex flex-col justify-center px-[5%]'
      >
        <div className="xl:container mx-auto">
          <h1 className="text-white font-bold text-5xl mb-8 pb-2 border-b-[3px] border-[#DAFF00] w-20">
            Música
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-12 justify-center">
            <SongCover
              imgUrl="./y-si-manana.jpeg"
              title="Y si mañana"
              spotify="https://open.spotify.com/track/5Uu8GM9LOeCxdxcvEqiMIY?si=2d59881f89e4470c"
              appleMusic="https://music.apple.com/co/album/y-si-ma%C3%B1ana/1725350823?i=1725350824"
              youtube="https://www.youtube.com/watch?v=DHTzPc8HGF8"
            />
            <SongCover
              imgUrl="./live-in-miami.jpeg"
              title="Live in Miami"
              spotify="https://open.spotify.com/album/27Axm5zxcdL9bqmSuvs2NE?si=kLlBZI-qSUu2NDrWLjs9qA&nd=1&dlsi=aec122810f444d34"
              appleMusic="https://music.apple.com/co/album/ronald-borjas-live-in-miami/1720277877"
              youtube="https://www.youtube.com/watch?v=CnSjWmgKmCM"
            />
            <SongCover
              imgUrl="./cuestion-de-tiempo.jpeg"
              title="Cuestión de tiempo"
              spotify="https://open.spotify.com/track/2Ys4SL3CJFD6OdDbQy1HK1?si=67cf7e62e4b04c86"
              appleMusic="https://music.apple.com/co/album/cuesti%C3%B3n-de-tiempo-single/1704036113"
              youtube="https://www.youtube.com/watch?v=4qtG8SpIBP4"
            />
            <div className="flex justify-center md:justify-center grid-cols-1 md:col-span-3">
              <div className="bg-white text-black px-4 flex items-center text-sm w-[260px] justify-between py-4 font-semibold rounded-2xl px-8">
                Escúchame en
                <div className="flex w-[70px] justify-between">
                  <a
                    href="https://open.spotify.com/artist/1c8m6rFeA6QIhImbIaZ7k2"
                    target="_blank"
                  >
                    <SiSpotify size="30px" className="text-[#1db954]" />
                  </a>
                  <a
                    href="https://music.apple.com/ba/artist/ronald-borjas/463001114"
                    target="_blank"
                  >
                    <SiApplemusic size="30px" className="text-[#fc3c44]" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider-2 bg-[#000] h-[50px]"></div>

      <div className="flex flex-col justify-center pl-[5%] pt-[100px] md:pt-0">
        <div className="" id="los-borjas-tour">
          {/* ============================================================================================================ */}

          {/* ======================================================================================== */}
          <div className="h-full grid grid-cols-1 md:grid-cols-9 items-start gap-2 justify-center bg-black bg-cover bg-[url('./assets/los-borjas-2.jpg')] bg-no-repeat bg-fixed">
            <div className="col-span-1 md:col-span-5 w-full justify-between items-center z-10 pr-[5%] md:pr-0 py-3">
              <div className="col-span-3 my-10">
                <h1 className="text-white font-bold text-5xl pb-2 border-b-[3px] border-[#DAFF00] w-100">
                  LOS BORJAS TOUR 2024
                </h1>
              </div>
              {/* {events.slice(0,2).map( ({poster_url, place, url}) => { */}
              {losBorjasTour.map(
                ({ poster_url, place, url, disabled, last }) => {
                  return (
                    <div
                      className={
                        "grid grid-cols-3 text-white font-semibold text-sm md:text-lg col-span-5 w-full justify-between items-center py-5" +
                        (!last ? " border-b-[1px]" : " ")
                      }
                    >
                      <div>
                        <img
                          className="w-[80px] md:w-[150px]"
                          src={poster_url}
                        />
                      </div>
                      <div>{place}</div>
                      <div>
                        {!disabled ? (
                          <a href={url} target="_blank" disabled={true}>
                            <div className="text-center text-xs md:text-sm py-3 px-2 md:py-3 ring-1 md:ring-2 hover:bg-white hover:text-black ring-white rounded-full hover:border-opacity">
                              Comprar tickets
                            </div>
                          </a>
                        ) : (
                          <div href={url} target="_blank" disabled={true}>
                            <div className="text-center text-xs md:text-sm py-3 px-2 md:py-3 ring-1 md:ring-2 ring-white rounded-full ">
                              SOLD OUT
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center pl-[5%] pt-[100px] md:pt-0">
        <div className="" id="conciertos">
          {/* ============================================================================================================ */}

          {/* ======================================================================================== */}
          <div className="grid grid-cols-1 md:grid-cols-9 items-end gap-2 justify-center bg-black bg-[url('./assets/bg-conciertos.jpg')] bg-no-repeat">
            <div className="col-span-1 md:col-span-5 w-full justify-between items-center z-10 pr-[5%] md:pr-0 py-3">
              <div className="col-span-3 my-10">
                <h1 className="text-white font-bold text-5xl pb-2 border-b-[3px] border-[#DAFF00] w-20">
                  Conciertos
                </h1>
              </div>
              {/* {events.slice(0,2).map( ({poster_url, place, url}) => { */}
              {events.map(
                ({ poster_url, place, url, disabled, last, ended }) => {
                  return (
                    <div
                      className={
                        "grid grid-cols-3 text-white font-semibold text-sm md:text-lg col-span-5 w-full justify-between items-center py-5" +
                        (!last ? " border-b-[1px]" : " ")
                      }
                    >
                      <div>
                        <img
                          className="w-[80px] md:w-[150px]"
                          src={poster_url}
                        />
                      </div>
                      <div>{place}</div>
                      <div>
                        {!disabled ? (
                          <a href={url} target="_blank" disabled={true}>
                            <div className="text-center text-xs md:text-sm py-3 px-2 md:py-3 ring-1 md:ring-2 hover:bg-white hover:text-black ring-white rounded-full hover:border-opacity">
                              Comprar tickets
                            </div>
                          </a>
                        ) : (
                          <div href={url} target="_blank" disabled={true}>
                            <div className="text-center text-xs md:text-sm py-3 px-2 md:py-3 ring-1 md:ring-2 ring-white rounded-full ">
                              {ended ? "FINALIZADO" : "SOLD OUT"}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
              {/* <Link to='/events' className='flex justify-end'>
                  <div className='text-white hover:text-[#c2c2c2] flex items-center text-sm w-[180px] justify-between py-4 font-semibold'>
                      Ver mas eventos <IoIosArrowForward size='26px' />
                  </div>
              </Link> */}
            </div>
            <div className="col-span-1 md:col-span-4 mt-[-20px] md:mt-0 z-0 md:flex md:justify-end">
              <img className="" src="/events.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
