import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'


export default function About(props) {
    useEffect(() => {
        document.title = props.title || "";
        }, [props.title]);
  return (
    <>
        <Navbar />
        <div className='bg-[url("./assets/about.jpg")] md:bg-[url("./assets/about.jpg")] bg-fixed bg-center'>
            <div className='container mx-auto flex px-10 md:px-0 md:flex-row flex-col h-screen justify-center items-center gap-8'>
                <img className='w-full md:w-1/3 rounded-full p-10' src="/about.jpg"/>
                <img className='w-full md:w-1/2' src="/logo-completo.png"/>
            </div>
            <div className='container mx-auto px-10 md:px-[20%] lg:px-[5%] h-full flex flex-col justify-center items-center pb-10'>
                <h1 className='text-white text-3xl pb-10 font-semibold'>Biografía Ronald Borjas</h1>
                <p className='text-white text-justify pb-10'>
                Ronald Borjas es actualmente considerado uno de los mayores exponentes de la salsa en Venezuela con una proyección internacional que cada año crece más. Es cantante, músico y compositor venezolano, nacido en Maracaibo, la capital del estado Zulia, el 19 de noviembre de 1981. <br /> <br />

A los 10 años de edad nació su amor por el timbal, pocos años después, a sus 14 años empezó a cantar como solista en la Orquesta Bochinche. A través de su adolescencia se destacó como solista en bandas como: Caribe, Asterisco, VHG y Bacanos. <br /> <br />

Cursó estudios superiores en la Universidad Cecilio Acosta (UNICA) donde obtuvo el título de Licenciado en Música - Educación Musical en el año 2002. En el mismo año se subió al escenario con la Super Banda de Venezuela GUACO, donde debutó como solista y compositor con el tema “Te vas” del álbum "El Sonido de Venezuela". <br /> <br />

No sólo se desarrolló como compositor, Ronald también toca otros instrumentos de percusión como el bajo, la guitarra y paralelamente también la trompeta. <br /> <br />

Luego de 11 años siendo una de las voces principales de Guaco, en el 2014, Ronald decide tomar otro camino y es ahí cuando empieza el gran proyecto de su vida, desarrollar su carrera como solista en géneros como la Salsa, Bachata y Pop. <br /> <br />

Ronald debuta en solitario con el tema “Te Doy Mi Voz” dedicado a sus fans, con el que recibe la nominación al Grammy Latino de “Mejor canción tropical”. Ese mismo año, su padrino musical, la leyenda de la Salsa, Oscar D´ León lo invita a su show en el Madison Square Garden de Nueva York, donde lo presenta ante miles de personas y lo bautiza como su “ahijado”. <br /> <br />

Ese mismo año, lanza “Te Encontraré” un tema que alcanzó el #1 de la cartelera radial venezolana Récord Report y su videoclip, filmado en New York logra entrar en el ranking de los videos más vistos del canal HTV. <br /> <br />

En 2015 inicia su expansión musical hacia otras fronteras, logrando éxito en países como Ecuador y Colombia donde su tercer single titulado “Dame Tu Amor” se posiciona en los primeros lugares de las emisoras radiales al igual que en su natal Venezuela,  mientras que en Perú, fue nombrado el tema “tropical del verano”. <br /> <br />

A finales de ese año lanza oficialmente su primer disco “Da Capo” con un evento que congregó en Caracas, Venezuela a un centenar de figuras del espectáculo nacional, así como la prensa, radio y tv del país en un evento sin precedentes. Este lanzamiento en sus primeras semanas, logró acumular más de 10.000 descargas digitales en su página web oficial www.ronaldborjas.com <br /> <br />

“Da Capo” contó con valiosas colaboraciones y un primer featuring con su padrino Oscar D´León, con quien grabó “La Quiero Para Mi”. Además, este álbum cuenta con temas de Amaury Gutierrez, Robert Vilera y su tío Neguito Borjas, entre otros. <br /> <br />

En 2016 inicia lanzando su tema “Cántalo” el cual se convirtió rápidamente en un himno de la alegría y logró posicionarse en el primer lugar de la radio venezolana. Ese año se alzó con varios premios nacionales de gran importancia como “Mara de Oro Internacional”, “Cacique De Oro”, Explosión Creativa y Supremacy, todos lo reconocieron como el “Cantante Salsa del Año”. <br /> <br />

Ese mismo año firma contrato con Sony Music como artista de la Región Andina y emprende su primera gira de conciertos internacional titulada “Da Capo tour” con la que visitó Aruba, Panamá y varias ciudades de Estados Unidos. <br /> <br />

En 2018 lanza “Loco por Volverte a Ver” tema que escribió junto a Nacho Mendoza, Cáceres y Sharo Torres, además hizo su segundo featuring junto al “Caballero de la Salsa” Gilberto Santarosa con quien estrenó “Ellas te van llevando” la primera colaboración con un artista internacional que también logró posicionarse como el número uno de las carteleras radiales. <br /> <br />







Poco después, en el año 2019, recibe doble nominación por “Álbum del año” con el tema “Lo que pase aquí” al ser uno de los temas del disco de Tonny Succar titulado “Más de Mi” y “Salsa del año” con el tema “No Queda Nada”. Además de la colaboración que hizo junto a “Quintero´s Salsa Proyect” que fue nominado a “Album Salsa del Año”. <br /> <br />

También en 2019 saca su segundo disco titulado “Bailamos Otra Vez” donde graba canciones de su autoría, así como también de Servando Primera, Oscarcito y repite Amaury Gutiérrez. De esta placa se desprenden éxitos como “Amor Demente” “Estoy Dispuesto” “Tu y Yo Tenemos” “Te Quiero para Mi” entre otros. <br /> <br />

Poco después anuncia su colaboración junto a otro compañero ex Guaco, Nelson Arrieta con quien estrena la canción “Sobran Los Momentos” que también da nombre al tour que harían juntos donde recorrieron países como Argentina, Chile, Ecuador, México, Colombia, España, Perú, Panamá y Estados Unidos logrando una gran aceptación por parte del público. <br /> <br />

Inicia el año 2020 con varios lanzamientos en colaboraciones. “Para Ustedes” del disco orígenes donde participó como invitado y logró el #1 de la radio en Venezuela, luego estrena junto a Victor Muñoz el tema “Juegos Eróticos” y hace promoción en su país con gran aceptación.  <br /> <br />

Inicia la etapa de “pandemia” y el confinamiento no lo detuvo. En marzo lanza la versión del tema “María Antonia” del cantante venezolano Gualberto Ibarreto, en abril estrena “Aunque Parezca Extraño” junto a Manuel Petit quien lo invita a esta colaboración. En mayo, junto a su tío Neguito Borjas, Jorge Luis Chacín y Nelson Arrieta se unen en un proyecto titulado “Los Mágicos”. En junio sale “Ahora Me Llama”, una canción que había grabado para Rumberos del Callejón. <br /> <br />

Por si fuese poco, en julio graba un concierto en íntimo donde invita a Victor Muñoz, su tío Neguito Borjas y a Nelson Arrieta para lanzarlo como regalo a sus fans a través de su canal de YouTube. <br /> <br />

Cierra los últimos meses de 2020 con el tema “Mía” escrito por Ronald y Salvador Solano que se convirtió en el himno de la ´Zumba´ logrando llegar a varios lugares del mundo. Luego de esto, estrena uno de sus temas “Ya Lo Decidí” con el que logra nuevamente el primer lugar de la radio y la aceptación de todo el público. <br /> <br />

Inicia el 2021siendo invitado especial al concierto de Gilberto Santarosa en el Teatro Filmore de Miami, donde interpretaron juntos el tema “Ellas Te Van Llevando”. Meses más tarde, lanza la canción que grabó a dúo con José Alberto “El Canario” titulado “A Mi Me Gusta” que logró rápidamente posicionarse en las principales listas de reproducción de las plataformas digitales en el mundo. Cierra ese año con el tema “Dime Cómo Hago” que grabó junto a Nelson Arrieta y Oscarcito, una salsa que se apoderó del gusto del público y hoy es una de las más coreadas en sus conciertos. <br /> <br />

2022 arranca con otra importante colaboración junto a Motiff, una canción titulada “Me Descontrola” que entró rápidamente en los primeros lugares de carteleras radiales y listados de plataformas digitales, además fue el tema escogido por la reconocida empresa Zumba para usarla como canción oficial en sus eventos mundiales. <br /> <br />

En 2022 también lanza un proyecto musical muy ambicioso que realizó junto a sus amigos y coterráneos Adelmo Gauna y Nando De La Gente llamado “Los Pikis” con el que estrenaron una serie de videos donde recopilaron éxitos de la salsa erótica de los años 80 y 90. Tal fue el éxito que en sólo dos meses lograron más de el millón de reproducciones en YouTube. <br /> <br />


En mayo de 2022 se estrena el concierto en vivo titulado “Sobran Los Momentos” donde Nelson Arrieta y Ronald Borjas invitan al fundador y voz líder de Guaco, Gustavo Aguado e interpretan juntos varios de los éxitos de la super banda de Venezuela, tales como “Vivo” “Pídeme” “Eres Más” y “Lo Eres Todo”. También estuvo Oscarcito intrepretando su éxito “Si Tu Me Besas” y el que tiene junto a Arrieta y Borjas “Dime Cómo Hago”. Todo este increíble material puede disfrutarse en la canal oficial de YouTube de Ronald Borjas y Nelson Arrieta. <br /> <br />

Para cerrar el año, estrena junto a su amigo musical, Acosta, el tema “Se prendió el Parampampan” una divertida salsa que une sus estilos musicales, que junto a un video grabado en Puerto Rico, lograron entrar al gusto del público convirtiendo esta canción en un himno de las fiestas venezolanas. Por su parte, Ronald Borjas, lanza un single en solitario titulado “Los Vecinos” que viene acompañado por un jocoso video dirigido por Alex Galán. <br /> <br />

2023 comenzó para Ronald Borjas con su tercer featuring con artista internacional, esta vez junto a la leyenda puertorriqueña, Tito Nieves. Una salsa romántica titulada “Te Busqué” que estuvo acompañada de un video donde se aprecian los dos cantantes en un performance e historia. El video cuenta actualmente con más de medio millón de reproducciones en el canal de YouTube oficial de Ronald. <br /> <br />

A los meses, Ronald se reencuentra con su primo Luis Fernando Borjas y graban el tema “Cuestión de Tiempo”, el cual alcanzó una gran aceptación por parte del público superando las dos millones y medias de visualizaciones del video y en las plataformas digitales se posicionó como uno de los más escuchado de “Lo Borjas”. Nombre que dio pie a una gira en conjunto con la que han recorrido las principales ciudades de Venezuela logrando sold out en cada una de ellas. <br /> <br />

Cierra el año con un gran concierto “Live in Miami” donde reúne nuevamente varios de sus compañeros musicales y amigos, como Oscarcito, Victor Muñoz, Motiff, Los Pikis y por primera vez desde su separación se unieron, Toto y Beet, del grupo Caibo con el que interpretaron junto a Ronald los éxitos “La Despedida” y “La Promesa”. Esta gran reunión de artistas se encuentra en el canal de YouTube de Borjas y también ha superado en menos de un año más de 600.000 reproducciones. <br /> <br />

2024 inició con su cuarto tema a dúo con un artista internacional, esta vez con el nicaragüense, “el príncipe de la salsa” Luis Enrique con quien graba “Y Si Mañana”. Se realizó un gran lanzamiento a través de los medios de comunicación en Venezuela y ha sido un tema que rápidamente se convirtió en el favorito del público de ambos cantantes y en pocas semanas se posicionó en las principales listas de salsa en Spotify. <br /> <br />

Luego de este éxito, Ronald inicia su “Tour LATAM 2024” por Colombia, Chile, Argentina, Perú, Panamá y Uruguay, países donde logró sold out en todas sus presentaciones y reencontrándose con su público luego de varios años. El éxito de esta gira, lo llevará muy pronto a Europa donde estará realizando varias presentaciones en España y otros países que aún no han sido anunciados. <br /> <br />
                </p>
            </div>
        </div>
        <Footer />
    </>

  )
}
