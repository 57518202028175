import {useState} from 'react'
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Navbar from './components/Navbar'
import Home from './screens/Home'
import Footer from './components/Footer';
import About from './screens/About';
import Music from './screens/Music';
import Contact from './screens/Contact';

function App() {

  const [isLoaded, setStatus] = useState(false);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <>
            <Home title='Ronald Borjas - Inicio' />
            <Footer />
          </>
        }/>
        <Route path="/about" element={
        <div>
          <About title='Ronald Borjas - Bio' />
        </div>
        }/>
        <Route path="/events" element={
        <div>
          <div>
            <Navbar />
            <h1> Events</h1>
          </div>
        </div>
        }/>
        <Route path="/music" element={
        <div>
          <Music title='Ronald Borjas - Música' />
        </div>
        }/>
        <Route path="/contact" element={
        <div>
          <Contact title='Ronald Borjas - Contacto' />
        </div>
        }/>
        <Route path="*" element={''}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
