import React from 'react'
import Navbar from './Navbar'
import Nav from './Nav'

export default function Header() {
  
  return (
    <div className='bg-[url("./assets/ronald-bg.jpg")] md:bg-[url("./assets/ronald-bg.jpg")] h-[100vh] lg:h-screen bg-cover bg-center'>
        <Navbar />
        <div className='flex flex-col justify-end pb-16 md:pb-0 md:justify-center 2xl:mx-10 items-left p-10 text-left gap-8 h-full'>
          <img className='w-full mt-[15vh] md:mt-0 md:w-[45%] 2xl:w-[30%] logo-shadow z-0' src="/logotipo.png" />
        </div>
    </div>
  )
}
