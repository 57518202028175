import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SongCover from "../components/Music/SongCover";
import { SiApplemusic, SiSpotify } from "react-icons/si";

export default function Music(props) {
  useEffect(() => {
    document.title = props.title || "";
    window.scrollTo(0, 0);
  }, [props.title]);
  return (
    <>
      <Navbar />
      <div className='bg-[url("./assets/about.jpg")] md:bg-[url("./assets/about.jpg")] bg-fixed bg-center pt-10'>
        <div
          id="music"
          className="mt-[-25px] py-[150px] flex flex-col justify-center px-[5%]"
        >
          <div className="xl:container mx-auto">
            <h1 className="text-white font-bold text-5xl mb-8 pb-2 border-b-[3px] border-[#DAFF00] w-20">
              Música
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-12 justify-center">
              <SongCover
                imgUrl="./joyas-que-bailan.jpeg"
                title="Te mentiría"
                spotify="https://open.spotify.com/track/1rtxjLsNE7WKVkZjDwYTwe?si=90f5e6c53101446b"
                appleMusic="https://music.apple.com/co/album/te-mentir%C3%ADa/1741987371?i=1741987374"
                youtube="https://www.youtube.com/watch?v=rbX24o742Ww"
              />
              <SongCover
                imgUrl="./joyas-que-bailan.jpeg"
                title="Atado a Tu Amor"
                spotify="https://open.spotify.com/track/3ZmpNK5R50513ncyEv4ynd?si=973e9acc9b794273"
                appleMusic="https://music.apple.com/co/album/atado-a-tu-amor/1741987371?i=1741987375"
                youtube="https://www.youtube.com/watch?v=Pm37pueEmqU"
              />
              <SongCover
                imgUrl="./joyas-que-bailan.jpeg"
                title="Desnuda"
                spotify="https://open.spotify.com/track/3NnFUiVf0Spt31xexxJPwf?si=e579dc21a6a74da2"
                appleMusic="https://music.apple.com/co/album/desnuda/1741987371?i=1741987376"
                youtube="https://www.youtube.com/watch?v=SFE34Dr3fL8"
              />
              <SongCover
                imgUrl="./joyas-que-bailan.jpeg"
                title="Dímelo"
                spotify="https://open.spotify.com/track/6Nj2CwSPdREBBrtv0qV6eg?si=090a2c75f3f54792"
                appleMusic="https://music.apple.com/co/album/d%C3%ADmelo/1741987371?i=1741987379"
                youtube="https://www.youtube.com/watch?v=j3KaFOSFmok"
              />
              <SongCover
                imgUrl="./joyas-que-bailan.jpeg"
                title="Piel de Ángel"
                spotify="https://open.spotify.com/track/1zSiHc3HQsVCZHwRF3fKKM?si=85ab6b7b39804fce"
                appleMusic="https://music.apple.com/co/album/piel-de-%C3%A1ngel/1741987371?i=1741987380"
                youtube="https://www.youtube.com/watch?v=bYhmBIsD3ZY"
              />
              <SongCover
                imgUrl="./joyas-que-bailan.jpeg"
                title="Tal vez"
                spotify="https://open.spotify.com/track/7piOE2uQxy9bNOMG9OGvZB?si=807decae8dfe4f8c"
                appleMusic="https://music.apple.com/co/album/tal-vez/1741987371?i=1741987383"
                youtube="https://www.youtube.com/watch?v=yjmDokzUGlU"
              />
              <SongCover
                imgUrl="./joyas-que-bailan.jpeg"
                title="Lo eres todo"
                spotify="https://open.spotify.com/track/6otwd6kTD2MCQBihN6x19M?si=c2b8497fe4cd4202"
                appleMusic="https://music.apple.com/co/album/lo-eres-todo/1741987371?i=1741987385"
                youtube="https://www.youtube.com/watch?v=oR9sWd3IpVA"
              />
              {/* Joyas que bailan */}
              <SongCover
                imgUrl="./y-si-manana.jpeg"
                title="Y si mañana"
                spotify="https://open.spotify.com/track/5Uu8GM9LOeCxdxcvEqiMIY?si=2d59881f89e4470c"
                appleMusic="https://music.apple.com/co/album/y-si-ma%C3%B1ana/1725350823?i=1725350824"
                youtube="https://www.youtube.com/watch?v=DHTzPc8HGF8"
              />
              <SongCover
                imgUrl="./live-in-miami.jpeg"
                title="Live in Miami"
                spotify="https://open.spotify.com/album/27Axm5zxcdL9bqmSuvs2NE?si=kLlBZI-qSUu2NDrWLjs9qA&nd=1&dlsi=aec122810f444d34"
                appleMusic="https://music.apple.com/co/album/ronald-borjas-live-in-miami/1720277877"
                youtube="https://www.youtube.com/watch?v=CnSjWmgKmCM"
              />
              <SongCover
                imgUrl="./cuestion-de-tiempo.jpeg"
                title="Cuestión de tiempo"
                spotify="https://open.spotify.com/track/2Ys4SL3CJFD6OdDbQy1HK1?si=67cf7e62e4b04c86"
                appleMusic="https://music.apple.com/co/album/cuesti%C3%B3n-de-tiempo-single/1704036113"
                youtube="https://www.youtube.com/watch?v=4qtG8SpIBP4"
              />
              <SongCover
                imgUrl="./me-descontrola.jpeg"
                title="Me Descontrola"
                spotify="https://open.spotify.com/track/34flp1Hbl6EkI6fudYKiHS?si=5beb8463fe264d7f"
                appleMusic="https://music.apple.com/us/album/me-descontrola/1610779339?i=1610779340"
                youtube="https://www.youtube.com/watch?v=M7oZquZM8to"
              />
              <SongCover
                imgUrl="./dime-como-hago.jpeg"
                title="Dime como hago"
                spotify="https://open.spotify.com/track/5SQMbspeEIYJPkXDWeiOJH?si=57825ce96ba042ad"
                appleMusic="https://music.apple.com/ba/album/dime-c%C3%B3mo-hago-single/1587852562"
                youtube="https://www.youtube.com/watch?v=EimmvqHNz4o"
              />
              <SongCover
                imgUrl="./a-mi-me-gusta.jpeg"
                title="A mí me gusta"
                spotify="https://open.spotify.com/track/3OfyRk370vzQR24Q2OkjbN?si=0af5cbe01848462a"
                appleMusic="https://music.apple.com/us/album/a-mi-me-gusta/1595157052?i=1595157055"
                youtube="https://www.youtube.com/watch?v=M_5zX9BdzzM"
              />
              <SongCover
                imgUrl="./ellas-te-van-llevando.jpeg"
                title="Ellas te van llevando"
                spotify="https://open.spotify.com/track/5RoEEMj96rwQyXm6WnJcUn?si=9ba94fc517fc4cde"
                appleMusic="https://music.apple.com/us/album/ellas-te-van-llevando/1443892361?i=1443892362"
                youtube="https://www.youtube.com/watch?v=2P0cD7mzaDI"
              />
              <SongCover
                imgUrl="./tu-y-yo-tenemos.jpeg"
                title="Tu y yo tenemos"
                spotify="https://open.spotify.com/track/2LOqLovvVa2ITe3jYOynep?si=d3301bfc16834d0c"
                appleMusic="https://music.apple.com/us/album/t%C3%BA-y-yo-tenemos/1589332257?i=1589332258"
                youtube="https://www.youtube.com/watch?v=nfpowwrSWJ4"
              />
              <SongCover
                imgUrl="./ya-lo-decidi.jpeg"
                title="Ya lo decidí"
                spotify="https://open.spotify.com/track/3RlnGjaHsos1NEsSXRtKOG?si=ee04799d9ad0425c"
                appleMusic="https://music.apple.com/ba/album/ya-lo-decid%C3%AD-single/1582915245"
                youtube="https://www.youtube.com/watch?v=j87uBLqAIKY"
              />
              <SongCover
                imgUrl="./los-pikis-vol-1.jpeg"
                title="Los pikis volumen 1"
                spotify="https://open.spotify.com/track/0qMnII8eRFEpEwuJQh5Cp2?si=4aae294c81c340e6"
                appleMusic="https://music.apple.com/us/album/los-pikis-%C3%A9xitos-de-salsa-er%C3%B3tica-vol-1/1597360579?i=1597360583"
                youtube="https://www.youtube.com/watch?v=q9BBbnps6eU&t=1240s"
              />
              <SongCover
                imgUrl="./los-pikis-vol-2.jpeg"
                title="Los pikis volumen 2"
                spotify="https://open.spotify.com/track/5b4ja1psqZ59QnyrdwudFW?si=51de47346da4461b"
                appleMusic="https://music.apple.com/us/album/los-pikis-%C3%A9xitos-de-salsa-er%C3%B3tica-vol-2/1631440092?i=1631440093"
                youtube="https://www.youtube.com/watch?v=Qs1vzRVxG0w"
              />
              <div className="flex justify-center md:justify-center grid-cols-1 md:col-span-3">
                <div className="bg-white text-black px-4 flex items-center text-sm w-[260px] justify-between py-4 font-semibold rounded-2xl px-8">
                  Escúchame en
                  <div className="flex w-[70px] justify-between">
                    <a
                      href="https://open.spotify.com/artist/1c8m6rFeA6QIhImbIaZ7k2"
                      target="_blank"
                    >
                      <SiSpotify size="30px" className="text-[#1db954]" />
                    </a>
                    <a
                      href="https://music.apple.com/ba/artist/ronald-borjas/463001114"
                      target="_blank"
                    >
                      <SiApplemusic size="30px" className="text-[#fc3c44]" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
