import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import {HiOutlineMailOpen, HiOutlinePhone} from 'react-icons/hi'

export default function Contact(props) {
    useEffect(() => {
        document.title = props.title || "";
        }, [props.title]);
  return (
    <>
        <Navbar />
        <div className='bg-[url("./assets/about.jpg")] md:bg-[url("./assets/about.jpg")] bg-fixed bg-center pt-10'>
            <div id="music" className='mt-[-25px] py-[150px] flex flex-col justify-center px-[5%]'>
                <div className='xl:container mx-auto'>
                    <h1 className='text-white font-bold text-5xl mb-8 pb-2 border-b-[3px] border-[#DAFF00] w-20'>Contacto</h1>
                    <div className='grid grid-cols-1 md:grid-cols-4 items-center gap-12 justify-center items-center'>
                        {/* <img className='rounded-2xl col-span-2' src='./contacto.jpg' /> */}
                        <div className='text-white font-bold col-span-2'>
                            <h1 className='font-bold text-3xl my-6'>Para contrataciones</h1>
                            
                            <a className='text-[#DAFF00] font-bold text-left flex items-center' href='mailto:borjasproducciones@gmail.com' type='email'>
                                <div className='ring-2 ring-[#DAFF00] p-5 rounded-2xl flex justify-center items-center gap-4 hover:bg-[#DAFF00] hover:text-black'> <HiOutlineMailOpen size='30px'/> Jesus Borjas - borjasproducciones@gmail.com</div>
                            </a>
                            <a className='text-[#DAFF00] font-bold text-left flex items-center my-8' href='tel:+17868300186'>
                                <div className='ring-2 ring-[#DAFF00] p-5 rounded-2xl flex justify-center items-center gap-4 hover:bg-[#DAFF00] hover:text-black'> <HiOutlinePhone size='30px'/> + 1 786 8300186</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>

  )
}
