import React from 'react'
import {SiApplemusic, SiSpotify} from 'react-icons/si' 
import {RiPlayCircleFill} from 'react-icons/ri' 

export default function SongCover({imgUrl, title, spotify, appleMusic, youtube}) {
  return (
    <div className='flex items-center justify-center rounded-2xl'>
        <div className='bg-white flex flex-col gap-5 rounded-2xl'>
            <div className='flex flex-col justify-center items-center'>
                <img className='w-full h-full rounded-t-2xl' src={imgUrl} />
                <a className='absolute opacity-75 hover:opacity-100 cursor-pointer' href={youtube} target="_blank">
                    <RiPlayCircleFill className='text-[#fff]' size='64px' />
                </a>
            </div>
            <h1 className='font-bold text-lg mx-6'>{title}</h1>
            <div className='font-semibold text-md mb-5 mx-6 flex items-center justify-between'>
                {spotify || appleMusic ? 'Escuchar en:' : ''}
                <div className='flex w-[60px] justify-between'>
                    { spotify ? <a href={spotify} target='_blank'> <SiSpotify className='text-[#1db954]' size='24px'/></a> : '' }

                    { appleMusic ? <a href={appleMusic} target='_blank'> <SiApplemusic className='text-[#fc3c44]' size='24px'/> </a> : null }
                </div>
            </div>
        </div>
    </div>
  )
}
