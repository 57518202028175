import React from 'react'
import {Link} from 'react-router-dom'
import {HiOutlineMenuAlt3, HiX} from 'react-icons/hi'
import Hamburger from 'hamburger-react'

export default function Navbar() {

    const [navbarOpen, setNavbarOpen] = React.useState(false);

    const handleClickScroll = (section) => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
      <>
        <nav className="absolute w-full flex px-2 py-3 mb-3">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between text-shadow max-w-none">
                <div className="z-20 w-full flex justify-between xl:w-auto xl:static xl:block xl:justify-start">
                    <Link className="leading-relaxed inline-block mr-4 py-2 whitespace-nowrap" to="/">
                        <img src='/isotipo.png'  className='w-[60px] logo-shadow '/>
                    </Link>
                    <button
                        className="text-white cursor-pointer text-xl leading-none border border-solid border-transparent rounded bg-transparent block xl:hidden outline-none focus:outline-none"
                        type="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}
                    >
                        {
                        <Hamburger toggled={navbarOpen} color="#fff" size={30}/>
                        
                        // navbarOpen ? <HiX className='text-3xl'/> : <HiOutlineMenuAlt3 className='text-3xl'/>
                        }
                    </button>
                </div>
                <div className={"z-10 xl:flex items-center justify-start xl:justify-end w-full xl:w-1/2" + (navbarOpen ? " flex bg-black absolute top-0 left-0 p-0 m-0 h-screen w-screen" : " hidden")}>
                    <ul className="flex flex-col justify-center xl:flex-row list-none ">
                        {/* <li className="nav-item"> 
                            <div className="px-3 py-2 flex items-center text-base font-normal leading-snug text-white hover:text-[#DAFF00] text-[32px] font-bold xl:font-normal uppercase xl:text-base xl:normal-case">
                                <span className="ml-2"><a className='cursor-pointer font-bold' onClick={async () => {
                                    if(window.location.pathname === '/') {
                                        console.log(window.location.pathname)
                                        const element = document.getElementById('los-borjas');
                                        if (element) {
                                          element.scrollIntoView({ behavior: 'smooth' });
                                        }
                                    } else if(window.location !== '/') {
                                        console.log(window.location.pathname)

                                        window.location.href = '/#los-borjas';
                                        
                                    }
                                }}>Los Borjas USA TOUR 2023</a></span>
                            </div>
                        </li> */}
                        <li className="nav-item"> 
                            <div className="px-3 py-2 flex items-center text-base font-normal leading-snug text-white hover:text-[#DAFF00] text-[32px] font-bold xl:font-normal uppercase xl:text-base xl:normal-case">
                                <span className="ml-2"><a className='cursor-pointer' onClick={async () => {
                                    if(window.location.pathname === '/') {
                                        console.log(window.location.pathname)
                                        const element = document.getElementById('conciertos');
                                        if (element) {
                                          element.scrollIntoView({ behavior: 'smooth' });
                                        }
                                    } else if(window.location !== '/') {
                                        console.log(window.location.pathname)

                                        window.location.href = '/#conciertos';
                                        
                                    }
                                }}>Conciertos</a></span>
                            </div>
                        </li>
                        <li className="nav-item"> 
                            <div className="px-3 py-2 flex items-center text-base font-normal leading-snug text-white hover:text-[#DAFF00] text-[32px] font-bold xl:font-normal uppercase xl:text-base xl:normal-case">
                                <span className="ml-2"><Link to='/'>Inicio</Link></span>
                            </div>
                        </li>
                        <li className="nav-item"> 
                            <div className="px-3 py-2 flex items-center text-base font-normal leading-snug text-white hover:text-[#DAFF00] text-[32px] font-bold xl:font-normal uppercase xl:text-base xl:normal-case">
                                <span className="ml-2"><Link to='/music'>Música</Link></span>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="px-3 py-2 flex items-center text-base font-normal leading-snug text-white hover:text-[#DAFF00] text-[32px] font-bold xl:font-normal uppercase xl:text-base xl:normal-case">
                                <span className="ml-2"><Link to='/about'>Bio</Link></span>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="px-3 py-2 flex items-center text-base font-normal leading-snug text-white hover:text-[#DAFF00] text-[32px] font-bold xl:font-normal uppercase xl:text-base xl:normal-case">
                                <span className="ml-2"><Link to='/contact'>Contacto</Link></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
      </>
    );
}
