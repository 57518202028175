import React from 'react'
import {Link} from 'react-router-dom'
import { FaYoutube, FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa'

export default function Footer() {
  return (
    <footer className="p-4 bg-black shadow md:px-6 md:py-8 border-t-[3px] border-[#DAFF00]">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <a href="https://www.ronaldborjas.com/" className="flex items-center mb-4 sm:mb-0 md:my-0 my-4">
                <img src="/isotipo.png" className="mr-3 h-20" alt="Ronald Borjas Logotipo" />
            </a>
            <div className='flex'>
                <a className='mx-3' href="https://www.instagram.com/ronaldborjas/" target="_blank" >
                    <FaInstagram className='text-[#fff] hover:text-[#DAFF00]' size='24px'/>
                </a>
                <a className='mx-3' href="https://www.facebook.com/RonaldBorjasOficial" target="_blank" >
                    <FaFacebook className='text-[#fff] hover:text-[#DAFF00]' size='24px'/>
                </a>
                <a className='mx-3' href="https://www.youtube.com/channel/UC7aq9b9MDyzztgKCj-AV-mA" target="_blank" >
                    <FaYoutube className='text-[#fff] hover:text-[#DAFF00]' size='24px'/>
                </a>
                <a className='mx-3' href="https://twitter.com/ronaldborjas" target="_blank" >
                    <FaTwitter className='text-[#fff] hover:text-[#DAFF00]' size='24px'/>
                </a>
            </div>
            <ul className="flex flex-wrap items-center justify-center text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
                <li>
                    <Link to="/" className="mr-4 hover:text-white md:mr-6 ">Inicio</Link>
                </li>
                <li>
                    <Link to="/music" className="mr-4 hover:text-white md:mr-6 ">Música</Link>
                </li>
                <li>
                    <Link to='/about' className="mr-4 hover:text-white md:mr-6 ">Bio</Link>
                </li>
                <li>
                    <Link to="/contact" className="hover:text-white">Contacto</Link>
                </li>
            </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 text-center dark:text-gray-400">© 2022 <a href="https://www.ronaldborjas.com/" className="hover:text-white">Ronald Borjas</a>. Todos los derechos reservados.
        </span>
    </footer>
  )
}
